import "./ProfileButton.css";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const ProfileButton = (props) => {
  const [showDD, setShowDD] = useState(props.dropDown);
  const { admin } = useAuth();

  useEffect(() => {
    setShowDD(props.dropDown);
  }, [props.dropDown]);

  return (
    <div className="profile-container-main">
      <div className="nav-profile-container">
        <div className="profile-link" onClick={props.toggle}>
          <button id="profile-icon-container">
            <div className={"profile-info-container"}>
              <div className="profile-name">{props.name.split(" ")[0]}</div>
              <div className="profile-points">{props.points}</div>
            </div>
            <img id="profile-icon" src={props.pic} alt="profile-icon" />
          </button>
        </div>
        {/* <div className="icon-labels"> */}
        <div className={admin ? "profile-label admin" : "profile-label"}>
          <ul className="profile-dropdown" onClick={props.clickAway}>
            {/* <li onClick={props.toggle}>
              <Link to="/user" state={props.info}>
                View Profile
              </Link>
            </li> */}
            <li onClick={props.toggle}>
              <Link to="/FAQ" state={props.info}>
                FAQ
              </Link>
            </li>
            <li onClick={props.toggle}>
              <Link to="/contact" state={props.info}>
                Contact Us
              </Link>
            </li>
            {admin && (
              <li onClick={props.toggle}>
                <Link to="/admin" state={props.info}>
                  Admin
                </Link>
              </li>
            )}
            <li onClick={props.toggle}>
              <a href="" onClick={props.logout}>
                Logout
              </a>
            </li>
          </ul>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProfileButton;
