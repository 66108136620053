import "./Prizes.css";

import PrizeCard from "../componenets/prizesComponents/PrizeCard";
import PrizeInfoPopup from "../componenets/prizesComponents/PrizeInfoPopup";
import { useState, useEffect } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../contexts/AuthContext";

const Prizes = () => {
  const { user, prizeList, update } = useAuth();
  const context = useOutletContext();
  const jwtT = localStorage.getItem("token");

  const [popupImage, setPopupImage] = useState("");
  const [popupName, setPopupName] = useState("");
  const [popupDesc, setPopupDesc] = useState("");
  const [popupShortDesc, setPopupShortDesc] = useState("");
  const [popupPrice, setPopupPrice] = useState("0");
  const [popupId, setPopupId] = useState("");
  const [shippable, setShippable] = useState(false);

  const toggleShow = () => {
    document.querySelector(".prize-backdrop").classList.toggle("show");
    document.querySelector(".prize-popup").classList.toggle("show");
    if (document.querySelector(".prize-popup").classList.contains("show")) {
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "overlay";
    }
  };

  const redeemPrize = (myId, quant, loader, successMsg, shipping = "") => {
    // console.log(shipping);
    loader(true);
    axios
      .post(
        process.env.REACT_APP_API_URL + "/api/redeemProduct",
        {
          productId: myId,
          quantity: quant,
          shippingAddress: shipping,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        loader(false);
        update();
        if (res.data.valid) {
          toast.success(res.data.message + successMsg, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.warning(res.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((res) => {
        loader(false);
        // context[3](localStorage.getItem("token"));
        toast.error(res.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const expand = (e) => {
    let prizeInd = e.target.closest(".prize-card").getAttribute("data-index");

    setPopupImage(prizeList[prizeInd]["image"]);
    setPopupName(prizeList[prizeInd]["name"]);
    setPopupPrice(prizeList[prizeInd]["pointCost"]);
    setPopupDesc(prizeList[prizeInd]["longDescription"]);
    setPopupShortDesc(prizeList[prizeInd]["shortDescription"]);
    setPopupId(prizeList[prizeInd]["_id"]);
    setShippable(prizeList[prizeInd]["shippable"]);

    toggleShow();
  };

  const creatPrizecards = () => {
    const toReturn = [];

    for (let i = 0; i < prizeList.length; i++) {
      toReturn.push(
        <PrizeCard
          img={prizeList[i]["image"]}
          key={i}
          ind={i}
          name={prizeList[i]["name"]}
          shortDesc={prizeList[i]["shortDescription"]}
          link={prizeList[i]["link"]}
          description={prizeList[i]["longDescription"]}
          cost={prizeList[i]["pointCost"]}
          shippable={prizeList[i]["shippable"]}
          click={expand}
          id={prizeList[i]["_id"]}
          redeem={redeemPrize}
        />
      );
    }

    return <div className="prize-card-container">{toReturn}</div>;
  };

  return (
    <div className="prizes-container">
      <div className="prize-backdrop"></div>
      {creatPrizecards()}
      <PrizeInfoPopup
        image={popupImage}
        name={popupName}
        shortDesc={popupShortDesc}
        description={popupDesc}
        price={popupPrice}
        shippable={shippable}
        click={toggleShow}
        id={popupId}
        redeem={redeemPrize}
        s
      />
    </div>
  );
};

export default Prizes;
