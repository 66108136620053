import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import Loader from "../generalComponenets/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditWindow from "./PopupWindow";
import { toast } from "react-toastify";

export default function PrizeMenu(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [prizeSearch, setPrizeSearch] = useState("");
  const searchData = useMemo(() => {
    if (prizeSearch !== "") {
      return data.filter((d) =>
        d.name.toLowerCase().startsWith(prizeSearch.toLowerCase())
      );
    } else {
      return data;
    }
  }, [data, prizeSearch]);
  const prizeCount = useMemo(() => {
    try {
      return searchData.length;
    } catch (e) {
      return 0;
    }
  }, [searchData]);
  const [popup, setPopup] = useState();
  const [popupEdit, setPopupedit] = useState(false);
  const { token } = useAuth();

  const getPrizes = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/admin/product", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((e) => {
        props.exp();
        console.log("Error occurred : " + e);
      });
  };

  const createPrizes = () => {
    let prizeName = document.querySelector("#prize-name-add");
    let prizePoints = document.querySelector("#prize-points-add");
    let prizeDesc = document.querySelector("#prize-description-add");
    let prizeBrief = document.querySelector("#prize-brief-add");
    let prizeImg = document.querySelector("#prize-image-add");

    if (!prizeName.value || !prizePoints.value || !prizeImg.value) {
      return;
    }

    let prizeData = {
      name: prizeName.value,
      pointCost: prizePoints.value,
      longDescription: prizeDesc.value,
      shortDescription: prizeBrief.value,
      image: prizeImg.value,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/admin/product`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: prizeData,
    };

    axios(config)
      .then((res) => {
        prizeName.value = "";
        prizePoints.value = "";
        prizeDesc.value = "";
        prizeBrief.value = "";
        prizeImg.value = "";
        getPrizes();
      })
      .catch((e) => {
        props.exp();
        console.log("Error occurred : " + e);
      });
  };

  const updatePrizes = (e) => {
    let prizeId = popup._id;
    let newName = document.querySelector("#admin-prize-name");
    let newPoints = document.querySelector("#admin-prize-points");
    let newDescription = document.querySelector("#admin-prize-description");
    let newBrief = document.querySelector("#admin-prize-brief");
    let newImage = document.querySelector("#admin-prize-image");

    let prizeData = JSON.stringify({
      name: newName.value,
      pointCost: newPoints.value,
      longDescription: newDescription.value,
      shortDescription: newBrief.value,
      image: newImage.value,
    });

    var config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/admin/product/${prizeId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: prizeData,
    };

    axios(config)
      .then(() => {
        getPrizes();
      })
      .catch((e) => {
        props.exp();
        console.log("Error occurred : " + e);
      });

    popupToggle();
  };

  const deletePrizes = (prizeId) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/admin/product/" + prizeId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        console.log("SUCCESS");
        getPrizes();
      })
      .catch((e) => {
        props.exp();
        console.log("Error occurred : " + e);
      });
  };

  const popupToggle = (e, open = false, edit) => {
    if (edit) {
      setPopupedit(true);
      setPopup(data.find((x) => x._id === e.target.getAttribute("data-id")));
    } else {
      setPopupedit(false);
    }

    if (open) {
      document.querySelector(".overlay").classList.add("show");
    } else {
      document.querySelector(".overlay").classList.remove("show");

      setPopup("");

      document.querySelector("#admin-prize-name").value = "";
      document.querySelector("#admin-prize-points").value = "";
      document.querySelector("#admin-prize-description").value = "";
      document.querySelector("#admin-prize-brief").value = "";
      document.querySelector("#admin-prize-image").value = "";
    }
  };

  useEffect(() => {
    getPrizes();
  }, []);

  return (
    <div id="adminmenu-container">
      <div id="admin-topbar">
        <div id="user-stats">Prizes: {prizeCount}</div>
        <div>
          <input
            type="text"
            id="user-search"
            placeholder="Search Prize..."
            onChange={(e) => setPrizeSearch(e.target.value)}
          />
        </div>
        <div>
          <button className="admin-btn-main" onClick={() => props.change(0)}>
            Back to Menu
          </button>
        </div>
      </div>
      {loading ? (
        <Loader style="large" />
      ) : (
        <div className="transaction-info-holder-container">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Points</TableCell>
                  <TableCell align="center">Description</TableCell>
                  <TableCell align="center">Brief</TableCell>
                  <TableCell align="center">Image URL</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.pointCost}</TableCell>
                    <TableCell align="left" className="limitHeight">
                      {row.longDescription}
                    </TableCell>
                    <TableCell align="center">{row.shortDescription}</TableCell>
                    <TableCell align="center">{row.image}</TableCell>
                    <TableCell align="center">
                      <button
                        className="admin-btn-main"
                        data-id={row._id}
                        onClick={(e) => popupToggle(e, true, true)}
                      >
                        Edit
                      </button>
                      <button
                        className="admin-btn-main dlt-btn"
                        data-id={row._id}
                        onClick={(e) =>
                          deletePrizes(
                            e.target.closest("button").getAttribute("data-id")
                          )
                        }
                      >
                        Delete
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <button
            className="admin-btn-main"
            onClick={(e) => popupToggle(e, true, false)}
          >
            Add
          </button>
        </div>
      )}
      <EditWindow
        type={"prizes"}
        event={popup}
        close={popupToggle}
        refresh={getPrizes}
        exp={props.exp}
        editMode={popupEdit}
        edit={updatePrizes}
      />
    </div>
  );
}
