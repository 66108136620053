import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import Loader from "../generalComponenets/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditWindow from "./PopupWindow";
import { toast } from "react-toastify";

export default function FaqMenu(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [faqSearch, setFaqSearch] = useState("");
  const searchData = useMemo(() => {
    if (faqSearch !== "") {
      return data.filter((d) =>
        d.title.toLowerCase().startsWith(faqSearch.toLowerCase())
      );
    } else {
      return data;
    }
  }, [data, faqSearch]);
  const faqCount = useMemo(() => {
    try {
      return searchData.length;
    } catch (e) {
      return 0;
    }
  }, [searchData]);
  const [popup, setPopup] = useState();
  const [popupEdit, setPopupedit] = useState(false);
  const { token } = useAuth();

  const getFaq = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/admin/faq", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((e) => {
        props.exp();
        console.log("Error occurred : " + e);
      });
  };

  const createFaq = () => {
    let faqTitle = document.querySelector("#admin-faq-question");
    let faqBody = document.querySelector("#admin-faq-answer");

    if (!faqTitle.value || !faqBody.value) {
      return;
    }

    let faqData = {
      title: faqTitle.value,
      body: faqBody.value,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/admin/faq`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: faqData,
    };

    axios(config)
      .then(() => {
        faqTitle.value = "";
        faqBody.value = "";
        getFaq();
      })
      .catch((e) => {
        props.exp();
        console.log("Error occurred : " + e);
      });

    popupToggle();
  };

  const updateFaq = (e) => {
    let faqId = e.target.closest("button").getAttribute("data-id");
    let newTitle = e.target
      .closest("tr")
      .querySelector("#admin-faq-title").value;
    let newBody = e.target.closest("tr").querySelector("#admin-faq-body").value;

    let faqData = {
      title: newTitle,
      body: newBody,
    };

    var config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/admin/faq/${faqId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: faqData,
    };

    axios(config)
      .then(() => {
        getFaq();
      })
      .catch((e) => {
        props.exp();
        console.log("Error occurred : " + e);
      });
  };

  const deleteFaq = (faqId) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/admin/faq/" + faqId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        getFaq();
      })
      .catch((e) => {
        props.exp();
        console.log("Error occurred : " + e);
      });
  };

  const popupToggle = (e, open = false, edit) => {
    if (edit) {
      setPopupedit(true);
      setPopup(data.find((x) => x._id === e.target.getAttribute("data-id")));
    } else {
      setPopupedit(false);
    }

    if (open) {
      document.querySelector(".overlay").classList.add("show");
    } else {
      document.querySelector(".overlay").classList.remove("show");

      setPopup("");

      document.querySelector("#admin-faq-question").value = "";
      document.querySelector("#admin-faq-answer").value = "";
    }
  };

  useEffect(() => {
    getFaq();
  }, []);

  return (
    <div id="adminmenu-container">
      <div id="admin-topbar">
        <div id="user-stats">Codes:{faqCount}</div>
        <div>
          <input
            type="text"
            id="user-search"
            placeholder="Search Question..."
            onChange={(e) => setFaqSearch(e.target.value)}
          />
        </div>
        <div>
          <button className="admin-btn-main" onClick={() => props.change(0)}>
            Back to Menu
          </button>
        </div>
      </div>
      {loading ? (
        <Loader style="large" />
      ) : (
        <div className="transaction-info-holder-container">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Question</TableCell>
                  <TableCell align="center" style={{ flex: "2" }}>
                    Answer
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{row.title}</TableCell>
                    <TableCell
                      align="left"
                      className="limitHeight"
                      style={{ flex: "2" }}
                    >
                      {row.body}
                    </TableCell>
                    <TableCell align="center">
                      <button
                        className="admin-btn-main"
                        data-id={row._id}
                        onClick={(e) => popupToggle(e, true, true)}
                      >
                        Edit
                      </button>
                      <button
                        className="admin-btn-main dlt-btn"
                        data-id={row._id}
                        onClick={(e) => deleteFaq(row._id)}
                      >
                        Delete
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <button
            className="admin-btn-main"
            onClick={(e) => popupToggle(e, true, false)}
          >
            Add
          </button>
        </div>
      )}
      <EditWindow
        type={"faq"}
        event={popup}
        close={popupToggle}
        refresh={getFaq}
        exp={props.exp}
        editMode={popupEdit}
        add={createFaq}
        edit={updateFaq}
      />
    </div>
  );
}
