import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { ToastContainer } from "react-toastify";
import "./Admin.css";
import AdminSelector from "../componenets/adminComponents/AdminSelector";
import axios from "axios";
import CodeMenu from "../componenets/adminComponents/CodeMenu";
import UserMenu from "../componenets/adminComponents/UserMenu";
import PrizeMenu from "../componenets/adminComponents/PrizeMenu";
import EventMenu from "../componenets/adminComponents/EventMenu";
import RedemptionMenu from "../componenets/adminComponents/RedemptionMenu";
import InquiryMenu from "../componenets/adminComponents/InquiryMenu";
import AdminHelp from "../componenets/adminComponents/AdminHelp";
import FaqMenu from "../componenets/adminComponents/FaqMenu";

export default function Admin() {
  const { admin, logout, token, setLoading } = useAuth();
  const [choice, setChoice] = useState(0);
  const navigate = useNavigate();

  if (!admin) {
    navigate("/", { replace: true });
  }

  const checkExpiry = () => {
    console.log("hi");
    axios
      .get(process.env.REACT_APP_API_URL + "/login/isLoggedIn", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {})
      .catch((e) => {
        logout();
      });
  };

  const adminSwitch = () => {
    switch (choice) {
      case 1:
        return <CodeMenu change={setChoice} exp={checkExpiry} />;
      case 2:
        return <UserMenu change={setChoice} exp={checkExpiry} />;
      case 3:
        return <PrizeMenu change={setChoice} exp={checkExpiry} />;
      case 4:
        return <EventMenu change={setChoice} exp={checkExpiry} />;
      case 5:
        return <FaqMenu change={setChoice} exp={checkExpiry} />;
      case 6:
        return <RedemptionMenu change={setChoice} exp={checkExpiry} />;
      case 7:
        return <InquiryMenu change={setChoice} exp={checkExpiry} />;
      case 8:
        return <AdminHelp change={setChoice} exp={checkExpiry} />;
      default:
        return <AdminSelector change={setChoice} />;
    }
  };

  return (
    <div className="admin-container">
      <div id="admin-header">
        <button
          className="admin-btn"
          onClick={() => {
            setLoading(false);
            navigate("/", { replace: true });
            window.location.reload();
          }}
        >
          Home
        </button>
        <h1 id="admin-banner">Admin Dashboard</h1>
        <button className="admin-btn" onClick={logout}>
          Logout
        </button>
      </div>
      <div id="admin-control-container">{adminSwitch()}</div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
