import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import Loader from "../generalComponenets/Loader";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditWindow from "./PopupWindow";

export default function EventMenu(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [eventSearch, setEventSearch] = useState("");
  const searchData = useMemo(() => {
    if (eventSearch !== "") {
      return data.filter((d) =>
        d.name.toLowerCase().startsWith(eventSearch.toLowerCase())
      );
    } else {
      return data;
    }
  }, [data, eventSearch]);
  const eventCount = useMemo(() => {
    try {
      return searchData.length;
    } catch (e) {
      return 0;
    }
  }, [searchData]);
  const [popup, setPopup] = useState();
  const [popupEdit, setPopupedit] = useState(false);
  const { token } = useAuth();

  const getEvents = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/admin/event", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((e) => {
        props.exp();
      });
  };

  const createEvent = () => {
    let eventName = document.querySelector("#admin-event-name");
    let eventPoints = document.querySelector("#admin-event-points");
    let eventDesc = document.querySelector("#admin-event-description");
    let eventStartDate = document.querySelector("#admin-event-sdate");
    let eventEndDate = document.querySelector("#admin-event-edate");
    let eventImg = document.querySelector("#admin-event-image");
    let eventType = document.querySelector("#admin-event-type");
    let eventLocation = document.querySelector("#admin-event-location");

    if (
      !eventName.value ||
      !eventPoints.value ||
      !eventType.value ||
      !eventImg.value
    ) {
      return;
    }

    let eventData = {
      name: eventName.value,
      pointReward: eventPoints.value,
      description: eventDesc.value,
      startDate: eventStartDate.value,
      endDate: eventEndDate.value,
      imgUrl: eventImg.value,
      eventType: eventType.value,
      location: eventLocation.value,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/admin/event`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: eventData,
    };

    axios(config)
      .then(() => {
        getEvents();
      })
      .catch((e) => {
        props.exp();
      });

    popupToggle();
  };

  useEffect(() => {}, [popup]);

  const updateEvent = (e) => {
    let eventId = popup._id;
    let newName = document.querySelector("#admin-event-name").value;
    let newPoints = document.querySelector("#admin-event-points").value;
    let newDescription = document.querySelector(
      "#admin-event-description"
    ).value;
    let newStartDate = document.querySelector("#admin-event-sdate").value;
    let newEndDate = document.querySelector("#admin-event-edate").value;
    let newImage = document.querySelector("#admin-event-image").value;
    let newType = document.querySelector("#admin-event-type").value;
    let newLocation = document.querySelector("#admin-event-location").value;

    let eventData = {
      name: newName,
      pointReward: newPoints,
      description: newDescription,
      startDate: newStartDate,
      endDate: newEndDate,
      imgUrl: newImage,
      eventType: newType,
      location: newLocation,
    };

    var config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/admin/event/${eventId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: eventData,
    };

    axios(config)
      .then(() => {
        getEvents();
      })
      .catch((e) => {
        props.exp();
      });

    popupToggle();
  };

  const deleteEvents = (eventId) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/admin/event/" + eventId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        getEvents();
      })
      .catch((e) => {
        props.exp();
      });
  };

  const changeLength = (e) => {
    if ([...e.target.classList].includes("limitHeight")) {
      e.target.classList.remove("limitHeight");
    } else {
      e.target.classList.add("limitHeight");
    }
  };

  const popupToggle = (e, open = false, edit) => {
    if (edit) {
      setPopupedit(true);
      setPopup(data.find((x) => x._id === e.target.getAttribute("data-id")));
    } else {
      setPopupedit(false);
    }

    if (open) {
      document.querySelector(".overlay").classList.add("show");
    } else {
      document.querySelector(".overlay").classList.remove("show");

      setPopup("");

      document.querySelector("#admin-code-type").value = "";
      document.querySelector("#admin-event-name").value = "";
      document.querySelector("#admin-event-points").value = "";
      document.querySelector("#admin-event-description").value = "";
      document.querySelector("#admin-event-sdate").value = "";
      document.querySelector("#admin-event-edate").value = "";
      document.querySelector("#admin-event-image").value = "";
      document.querySelector("#admin-event-type").value = "";
      document.querySelector("#admin-event-location").value = "";
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div id="adminmenu-container">
      <div id="admin-topbar">
        <div id="user-stats">Prizes: {eventCount}</div>
        <div>
          <input
            type="text"
            id="user-search"
            placeholder="Search Event..."
            onChange={(e) => setEventSearch(e.target.value)}
          />
        </div>
        <div>
          <button className="admin-btn-main" onClick={() => props.change(0)}>
            Back to Menu
          </button>
        </div>
      </div>
      {loading ? (
        <Loader style="large" />
      ) : (
        <div className="transaction-info-holder-container">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Event Name</TableCell>
                  <TableCell align="center">Points Reward</TableCell>
                  <TableCell align="center" style={{ flex: "2" }}>
                    Description
                  </TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Location</TableCell>
                  <TableCell align="center">Image Url</TableCell>
                  <TableCell align="center">Event Type</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.pointReward}</TableCell>
                    <TableCell
                      align="left"
                      className="limitHeight"
                      onClick={changeLength}
                      style={{ flex: "2" }}
                    >
                      {row.description}
                    </TableCell>
                    <TableCell align="center">
                      <div>
                        <b>Start: </b>
                        {(() => {
                          let d = new Date(row.startDate);

                          return `${d.getFullYear()}-${(
                            "0" +
                            (d.getMonth() + 1)
                          ).slice(-2)}-${("0" + d.getDate()).slice(-2)} -  ${(
                            "0" + d.getHours()
                          ).slice(-2)}:${("0" + d.getMinutes()).slice(-2)}`;
                        })()}
                        <br></br>
                        <br></br>
                        <b>End: </b>
                        {(() => {
                          let d = new Date(row.endDate);

                          return `${d.getFullYear()}-${
                            d.getMonth() + 1
                          }-${d.getDate()}  -  ${d.getHours()}:${d.getMinutes()}`;
                        })()}
                      </div>
                    </TableCell>
                    <TableCell align="center">{row.location}</TableCell>
                    <TableCell align="center" max-width="200px">
                      <a href={row.imgUrl} target="_blank" rel="noreferrer">
                        {row.imgUrl}
                      </a>
                    </TableCell>
                    <TableCell align="center">{row.eventType}</TableCell>
                    <TableCell align="center">
                      <button
                        className="admin-btn-main"
                        data-id={row._id}
                        onClick={(e) => popupToggle(e, true, true)}
                      >
                        Edit
                      </button>
                      <button
                        className="admin-btn-main dlt-btn"
                        data-id={row._id}
                        onClick={(e) =>
                          deleteEvents(
                            e.target.closest("button").getAttribute("data-id")
                          )
                        }
                      >
                        Delete
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <button
            className="admin-btn-main"
            onClick={(e) => popupToggle(e, true, false)}
          >
            Add
          </button>
        </div>
      )}
      <EditWindow
        type={"events"}
        event={popup}
        close={popupToggle}
        refresh={getEvents}
        exp={props.exp}
        editMode={popupEdit}
        add={createEvent}
        edit={updateEvent}
      />
    </div>
  );
}
