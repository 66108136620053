import "./Navbar.css";

import axios from "axios";
import { Link, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";

import NavbarButton from "../componenets/navbarComponents/NavbarButton";
import ProfileButton from "../componenets/navbarComponents/ProfileButton";
import FooterIcon from "../componenets/navbarComponents/FooterIcon";
import HamburgerMenu from "../componenets/navbarComponents/HamburgerMenu";
import Sidemenu from "../componenets/navbarComponents/Sidemenu";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Navbar = (props) => {
  const { user, logout } = useAuth();

  const [selected, setSelected] = useState("home");
  const [menu, setMenu] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const linkClick = () => {
    setSelected(undefined);
    setMenu(false);
    setDropdown(false);
  };

  const linkClickb = (toSlct) => {
    setSelected(toSlct);
    setMenu(false);
    setDropdown(false);
    toggleSidemenu();
  };

  const checkExpiry = (token) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/login/isLoggedIn", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((e) => {
        logout();
      });
  };
  checkExpiry(localStorage.getItem("token"));

  const hideDropdown = (e) => {
    let dropDwn = document.querySelector(".profile-label");

    try {
      if (
        !dropDwn.contains(e.target) &&
        !document.querySelector("#profile-icon-container").contains(e.target)
      ) {
        setDropdown(false);
        document.querySelector(".profile-label").classList.remove("show");
      }
    } catch (e) {}
  };

  const toggleDropdown = (e) => {
    document.querySelector(".profile-label").classList.toggle("show");
  };

  const toggleSidemenu = () => {
    document.querySelector(".side-menu").classList.toggle("show");
    document.querySelector(".overlay").classList.toggle("show");
  };

  useEffect(() => {
    // if (
    //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //     navigator.userAgent
    //   )
    // ) {
    //   document.querySelector(".main-container").classList.add("mobile");
    // }
    window.addEventListener("scroll", () => {
      if (document.querySelector(".side-menu").classList.contains("show")) {
        document.querySelector(".side-menu").classList.remove("show");
        document.querySelector(".overlay").classList.remove("show");
      }
    });
    let currentLoc =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];

    if (currentLoc === "home" || currentLoc === "") {
      setSelected("home");
    } else if (currentLoc === "prizes") {
      setSelected("prizes");
    } else if (currentLoc === "events") {
      setSelected("events");
    } else if (currentLoc === "contact") {
      setSelected(undefined);
    } else {
      setSelected(undefined);
    }
  }, []);

  window.addEventListener(
    "resize",
    function (event) {
      if (window.screen.width > 800) {
        document.querySelector(".side-menu").classList.remove("show");
        document.querySelector(".overlay").classList.remove("show");
      } else if (window.screen.width < 801) {
        document.querySelector(".profile-label").classList.remove("show");
      }
    },
    true
  );

  return (
    <div className="main-container" onClick={hideDropdown}>
      <div
        className="overlay"
        onClick={toggleSidemenu}
        onScroll={() => console.log("hi")}
      ></div>
      <div className="navbar">
        <div className="isss-logo-container">
          <Link to="home">
            <img
              src={require("../assets/images/general/logo.png")}
              alt="isss logo"
              className="isss-logo"
              onClick={() => setSelected("home")}
            />
          </Link>
        </div>
        <div className="navbar-links">
          <Link
            to={"/home"}
            className={selected === "home" ? "selected" : ""}
            onClick={() => setSelected("home")}
          >
            Home
          </Link>
          <Link
            to={"/prizes"}
            className={selected === "prizes" ? "selected" : ""}
            onClick={() => setSelected("prizes")}
          >
            Prizes
          </Link>
          <Link
            to={"/events"}
            className={selected === "events" ? "selected" : ""}
            onClick={() => setSelected("events")}
          >
            Events
          </Link>
          <Link
            to={"/user"}
            className={selected === "user" ? "selected" : ""}
            onClick={() => setSelected("user")}
          >
            Profile
          </Link>
          {/* <NavbarButton
            name="home"
            select={selected === "home" ? true : false}
            click={() => setSelected("home")}
            info={user}
          />
          <NavbarButton
            name="prizes"
            select={selected === "prizes" ? true : false}
            click={() => setSelected("prizes")}
            info={user}
          />
          <NavbarButton
            name="events"
            select={selected === "events" ? true : false}
            click={() => setSelected("events")}
            info={user}
          /> */}
        </div>
        <ProfileButton
          name={user.name}
          points={user.points}
          pic={user.picture}
          clickAway={linkClick}
          info={user}
          dropDown={dropdown}
          toggle={toggleDropdown}
          logout={logout}
        />
        <HamburgerMenu click={toggleSidemenu} />
      </div>
      <Outlet context={linkClick} />
      <Sidemenu
        info={user}
        click={linkClickb}
        close={toggleSidemenu}
        logout={logout}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="footer">
        {/* <div className="footor-top">
          <FooterIcon
            name="instagram"
            link="https://www.instagram.com/isssrewards/"
          />
          <FooterIcon
            name="facebook"
            link="https://www.facebook.com/ISSSUAlberta/"
          />
          <FooterIcon
            name="twitter"
            link="https://twitter.com/isss_uofa?lang=en"
          />
        </div> */}
        <div className="footor-bottom">
          ©{new Date().getFullYear()} ISSS. All rights Reserved.
        </div>
      </div>
    </div>
  );
};
export default Navbar;
