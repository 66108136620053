// import { GoogleLogin } from "react-google-login";
// import { gapi } from "gapi-script";
import "./GoogleLoginBtn.css";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
// import { GoogleLogin } from "@react-oauth/google";

const GoogleLoginBtn = (props) => {
    return (
        // <GoogleLogin
        //     clientId="522881790389-smrdeddni44olpe10kem9eai5d0rjpss.apps.googleusercontent.com"
        //     render={(renderProps) => (
        //         <button
        //             onClick={renderProps.onClick}
        //             disabled={renderProps.disabled}
        //             className="google-btn"
        //         >
        //             <img
        //                 src={require("../images/search.png")}
        //                 alt="Google Logo"
        //                 id="google-logo"
        //             />
        //             Login with Google
        //         </button>
        //     )}
        //     onSuccess={props.success}
        //     onFailure={props.failure}
        //     isSignedIn={true}
        //     cookiePolicy={"single_host_origin"}
        // />
        <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CID}>
            <GoogleLogin onSuccess={props.success} onError={props.failure} />
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginBtn;
