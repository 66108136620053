import React from "react";
import Loader from "../generalComponenets/Loader";

export default function AdminHelp(props) {
  return (
    <div id="adminmenu-container">
      <button className="admin-btn-main" onClick={() => props.change(0)}>
        Back to Menu
      </button>
      <div
        className="transaction-info-holder-container"
        style={{ fontSize: "30px", textAlign: "center" }}
      >
        For any questions or inquiries email: <br />
        <b>
          <a href="mailto:m.dayyan367@gmail.com" color="white">
            m.dayyan367@gmail.com
          </a>
        </b>
      </div>
    </div>
  );
}
