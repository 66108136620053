import "./Sidemenu.css";

import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const Sidemenu = (props) => {
  const { admin } = useAuth();
  // console.log(props);
  return (
    <div className="side-menu">
      <button className="sidemenu-close" onClick={props.close}>
        ✖
      </button>
      <div className="side-menu-links-container">
        <Link
          to="home"
          className="side-menu-links"
          state={props.info}
          onClick={() => props.click("home")}
        >
          <div>Home</div>
        </Link>
        <Link
          to="prizes"
          className="side-menu-links"
          state={props.info}
          onClick={() => props.click("prizes")}
        >
          <div>Prizes</div>
        </Link>
        <Link
          to="events"
          className="side-menu-links"
          state={props.info}
          onClick={() => props.click("events")}
        >
          <div>Events</div>
        </Link>
        <Link
          to="user"
          className="side-menu-links"
          state={props.info}
          onClick={() => props.click(undefined)}
        >
          <div>User Profile</div>
        </Link>
        <Link
          to="FAQ"
          className="side-menu-links"
          state={props.info}
          onClick={() => props.click(undefined)}
        >
          <div>FAQ</div>
        </Link>
        <Link
          to="contact"
          className="side-menu-links"
          state={props.info}
          onClick={() => props.click(undefined)}
        >
          <div>Contact</div>
        </Link>
        {admin && (
          <Link
            to="admin"
            className="side-menu-links"
            state={props.info}
            onClick={() => props.click(undefined)}
          >
            <div>Admin</div>
          </Link>
        )}
        <a href="/" className="side-menu-links" onClick={props.logout}>
          <div>Logout</div>
        </a>
      </div>
    </div>
  );
};

export default Sidemenu;
