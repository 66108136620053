import "./FAQ.css";

import FAQBar from "../componenets/faqComponents/FAQBar";
import { useAuth } from "../contexts/AuthContext";
// import Dropdown from "../Componenets/Dropdown";

const FAQ = () => {
  const { faq } = useAuth();

  const populate = () => {
    let toReturn = [];

    for (let i = 0; i < faq.length; i++) {
      toReturn.push(
        <FAQBar key={i} question={faq[i].title} body={faq[i].body} />
      );
    }

    return toReturn;
  };

  return <div className="faq-container">{populate()}</div>;
};

export default FAQ;
