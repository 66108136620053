import "./PrizeCard.css";
import Loader from "../generalComponenets/Loader";
import { useEffect, useState } from "react";

const PrizeCard = (props) => {
  const [face, setFace] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(props.cost);
  const [loading, setLoading] = useState(false);

  const flip = (e) => {
    setFace(false);
  };

  const flipOver = (e) => {
    setFace(true);
  };

  const changeQuant = (e) => {
    setQuantity(e.target.value);
  };

  const redeem = () => {
    props.redeem(props.id, quantity, setLoading);
  };

  useEffect(() => {
    setPrice(quantity * props.cost);
  }, [props.cost, quantity]);

  return (
    <div
      className="prize-card"
      onMouseOver={flip}
      onMouseOut={flipOver}
      data-index={props.ind}
    >
      <div className="prize-content front">
        <img src={props.img} alt="prize" className="prize-img" />
        <div className="prize-info">
          <div className="prize-name">{props.name}</div>
          <div className="prize-cost">{price} ISSSicles</div>
        </div>
      </div>
      <div className="prize-content back">
        <div className="prize-header-flip">
          <div className="prize-name">{props.name}</div>
          {/* <div className="prize-short-description">
            <a href={props.link} target="_blank" rel="noreferrer">
              {props.shortDesc}
            </a>
          </div> */}
        </div>
        <div className="prize-description">{props.description}</div>
        <div className="prize-info">
          <div className="prize-cost-flip">{price} ISSSicles</div>
          <div className="prize-redeem">
            {/* <input
              type="number"
              className="prize-input"
              min={1}
              max={5}
              defaultValue={quantity}
              onInput={changeQuant}
            /> */}
            <button
              type="button"
              className="prize-redeem-btn"
              onClick={props.click}
            >
              {loading ? <Loader style="prize" /> : "Redeem"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrizeCard;
