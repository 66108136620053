import "./Events.css";

import EventCard from "../componenets/eventsComponents/EventCard";
import SurveyCard from "../componenets/eventsComponents/SurveyCard";
import { useState, useEffect } from "react";
import axios from "axios";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../contexts/AuthContext";

const Events = () => {
  const { eventList } = useAuth();

  const createEventCard = () => {
    const toReturn = [];

    for (let i = 0; i < eventList.length; i++) {
      if (eventList[i].eventType === "Event") {
        toReturn.push(
          <EventCard
            key={i}
            image={eventList[i]["imgUrl"]}
            name={eventList[i]["name"]}
            startDate={eventList[i]["startDate"]}
            endDate={eventList[i]["endDate"]}
            points={eventList[i]["pointReward"]}
            description={eventList[i]["description"]}
            link={eventList[i]["eventLink"]}
            ind={eventList[i]["_id"]}
            location={eventList[i]["location"]}
          />
        );
      }
    }
    return (
      <div className="survey-cards-container-outer">
        <div className="survey-cards-top">
          {/* <div className="survey-cards-header">Events</div> */}
          {/* <div className="survey-cards-line"></div> */}
        </div>
        <div className="survey-cards-container">{toReturn}</div>
      </div>
    );
  };

  const createSurveyCard = () => {
    const toReturn = [];

    for (let i = 0; i < eventList.length; i++) {
      if (("survey: ", eventList[i].eventType === "Survey")) {
        toReturn.push(
          <SurveyCard
            key={i}
            image={eventList[i]["imgUrl"]}
            name={eventList[i]["name"]}
            date={eventList[i]["date"].split("T")[0]}
            points={eventList[i]["pointReward"]}
            description={eventList[i]["description"]}
            link={eventList[i]["surveyLink"]}
          />
        );
      }
    }
    return (
      <div className="survey-cards-container-outer">
        {/* <div className="survey-cards-header">Surveys</div> */}
        {/* <div className="survey-cards-line"></div> */}
        <div className="survey-cards-container">{toReturn}</div>
      </div>
    );
  };

  return (
    <div className="survey-container">
      {eventList.length === 0 ? (
        <h4 className="no-events-label">
          No scheduled events :'(. Check back later
        </h4>
      ) : null}
      {createEventCard()}
      {createSurveyCard()}
    </div>
  );
};

export default Events;
