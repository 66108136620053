import React, { useState } from "react";
import QrReader from "react-qr-scanner";
import Loader from "../generalComponenets/Loader";
import "./QRScanner.css";

export default function QRScanner(props) {
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);

  const previewStyle = {
    // height: 240,
    width: "100%",
  };

  return (
    <div id="qrscanner-container">
      <button id="qrscanner-close-btn" onClick={props.toggle}>
        ✖
      </button>
      {!failed && loading && <Loader style={"scanner"} />}
      {failed ? (
        <div id="qrscanner-failed">Something went wrong :/</div>
      ) : (
        <QrReader
          style={previewStyle}
          facingMode="rear"
          legacyMode={true}
          onLoad={() => setLoading(false)}
          onError={(e) => {
            console.log(e);
            setFailed(true);
          }}
          onScan={(d) => {
            props.scanned(null, d);
          }}
        />
      )}
    </div>
  );
}
