import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default function PrivateRoute(props) {
  const { user, admin } = useAuth();

  if (user) {
    if (
      (!admin && props.access === "admin") ||
      (user.accessLevel !== "volunteer" && props.access === "volunteer")
    ) {
      return <Navigate to="/" />;
    } else {
      return <Outlet />;
    }
  } else {
    return <Navigate to="/login" />;
  }

  //   return user ? <Outlet /> : <Navigate to="/login" />;
}

// if (user) {
//     if ((admin && props.adminNeed) || (!admin && !props.adminNeed)) {
//       return <Outlet />;
//     } else if (admin && !props.adminNeed) {
//       return <Navigate to="/admin" />;
//     } else {
//       return <Navigate to="/" />;
//     }
//   } else {
//     return <Navigate to="/login" />;
//   }
