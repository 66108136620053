import React from "react";

export default function AdminSelector(props) {
  return (
    <div className="grid-container">
      <button className="grid-cell" onClick={() => props.change(1)}>
        Code Maintenance
      </button>
      <button className="grid-cell" onClick={() => props.change(2)}>
        User Maintenance
      </button>
      <button className="grid-cell" onClick={() => props.change(3)}>
        Prize Maintenance
      </button>
      <button className="grid-cell" onClick={() => props.change(4)}>
        Event Maintenance
      </button>
      <button className="grid-cell" onClick={() => props.change(5)}>
        FAQ Maintenance
      </button>
      <button className="grid-cell" onClick={() => props.change(6)}>
        Redemptions
      </button>
      <button className="grid-cell" onClick={() => props.change(7)}>
        User Inquiries
      </button>
      <button className="grid-cell help" onClick={() => props.change(8)}>
        Help
      </button>
    </div>
  );
}
