import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import Loader from "../generalComponenets/Loader";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditWindow from "./PopupWindow";

export default function CodeMenu(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [popup, setPopup] = useState();
  const [popupEdit, setPopupedit] = useState(false);
  const [codeSearch, setCodeSearch] = useState("");
  const searchData = useMemo(() => {
    if (codeSearch !== "") {
      return data.filter((d) =>
        d.code.toLowerCase().startsWith(codeSearch.toLowerCase())
      );
    } else {
      return data;
    }
  }, [data, codeSearch]);
  const codeCount = useMemo(() => {
    try {
      return searchData.length;
    } catch (e) {
      return 0;
    }
  }, [searchData]);
  const { token } = useAuth();

  const getCodes = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/admin/code", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((e) => {
        props.exp();
      });
  };

  const postCode = () => {
    let codeType = document.querySelector("#admin-code-type");
    let codeVal = document.querySelector("#admin-code-name");
    let pointAmountVal = document.querySelector("#admin-code-points");
    let descriptionVal = document.querySelector("#admin-code-description");
    let useVal = document.querySelector("#admin-code-use");
    let validFromVal = document.querySelector("#admin-code-valid-from");
    let validUntilVal = document.querySelector("#admin-code-valid-until");

    if (codeType.value === "Static") {
      if (
        !codeVal.value ||
        !pointAmountVal.value ||
        !descriptionVal.value ||
        !useVal.value ||
        !validFromVal.value ||
        !validUntilVal.value
      ) {
        console.log("return");
        return false;
      }

      let codeData = {
        code: codeVal.value,
        pointAmount: pointAmountVal.value,
        description: descriptionVal.value,
        validFrom: validFromVal.value,
        validUntil: validUntilVal.value,
        codesUses: useVal.value,
      };

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/admin/code`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: codeData,
      };

      // if ()
      axios(config)
        .then((res) => {
          getCodes();
        })
        .catch((e) => {
          props.exp();
          console.log("Error occurred : " + e);
        });
      popupToggle();
      return true;
    } else if (codeType === "Dynamic") {
      return;
    }
  };

  const updateCode = (e) => {
    let codeId = popup._id;
    let codeVal = document.querySelector("#admin-code-name");
    let pointAmountVal = document.querySelector("#admin-code-points");
    let descriptionVal = document.querySelector("#admin-code-description");
    let useVal = document.querySelector("#admin-code-use");
    let validFromVal = document.querySelector("#admin-code-valid-from");
    let validUntilVal = document.querySelector("#admin-code-valid-until");

    let codeData = {
      code: codeVal.value,
      pointAmount: pointAmountVal.value,
      description: descriptionVal.value,
      validFrom: validFromVal.value,
      validUntil: validUntilVal.value,
      codesUses: useVal.value,
    };

    var config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/admin/code/${codeId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: codeData,
    };

    axios(config)
      .then(() => {
        getCodes();
      })
      .catch((e) => {
        props.exp();
      });

    popupToggle();
  };

  const deleteCode = (codeId) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/admin/code/" + codeId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        getCodes();
      })
      .catch((e) => {
        props.exp();
      });
  };

  const popupToggle = (e, open = false, edit) => {
    if (edit) {
      setPopupedit(true);
      setPopup(data.find((x) => x._id === e.target.getAttribute("data-id")));
    } else {
      setPopupedit(false);
    }

    if (open) {
      document.querySelector(".overlay").classList.add("show");
    } else {
      document.querySelector(".overlay").classList.remove("show");

      setPopup("");

      try {
        document.querySelector("#admin-code-type").value = "";
        document.querySelector("#admin-code-name").value = "";
        document.querySelector("#admin-code-points").value = "";
        document.querySelector("#admin-code-description").value = "";
        document.querySelector("#admin-code-use").value = "";
        document.querySelector("#admin-code-valid-from").value = "";
        document.querySelector("#admin-code-valid-until").value = "";
      } catch (e) {}
    }
  };

  useEffect(() => {
    getCodes();
  }, []);

  return (
    <div id="adminmenu-container">
      <div id="admin-topbar">
        <div id="user-stats">Codes:{codeCount}</div>
        <div>
          <input
            type="text"
            id="user-search"
            placeholder="Search Code..."
            onChange={(e) => setCodeSearch(e.target.value)}
          />
        </div>
        <div>
          <button className="admin-btn-main" onClick={() => props.change(0)}>
            Back to Menu
          </button>
        </div>
      </div>
      {loading ? (
        <Loader style="large" />
      ) : (
        <div className="transaction-info-holder-container">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Code Type</TableCell>
                  <TableCell align="center">Code</TableCell>
                  <TableCell align="center">Points</TableCell>
                  <TableCell align="center">Description</TableCell>
                  <TableCell align="center">Code Use</TableCell>
                  <TableCell align="center">Valid Duration</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">Static</TableCell>
                    <TableCell align="center">{row.code}</TableCell>
                    <TableCell align="center">{row.pointAmount}</TableCell>
                    <TableCell align="left">{row.description}</TableCell>
                    <TableCell align="center">{row.codesUses}</TableCell>
                    <TableCell align="center">
                      <div>
                        <b>Start: </b>
                        {(() => {
                          let d = new Date(row.validFrom);

                          return `${d.getFullYear()}-${(
                            "0" +
                            (d.getMonth() + 1)
                          ).slice(-2)}-${("0" + d.getDate()).slice(-2)} -  ${(
                            "0" + d.getHours()
                          ).slice(-2)}:${("0" + d.getMinutes()).slice(-2)}`;
                        })()}
                        <br></br>
                        <br></br>
                        <b>End: </b>
                        {(() => {
                          let d = new Date(row.validUntil);

                          return `${d.getFullYear()}-${
                            d.getMonth() + 1
                          }-${d.getDate()}  -  ${d.getHours()}:${d.getMinutes()}`;
                        })()}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <button
                        className="admin-btn-main"
                        data-id={row._id}
                        onClick={(e) => popupToggle(e, true, true)}
                      >
                        Edit
                      </button>
                      <button
                        className="admin-btn-main dlt-btn"
                        data-id={row._id}
                        onClick={(e) =>
                          deleteCode(
                            e.target.closest("button").getAttribute("data-id")
                          )
                        }
                      >
                        Delete
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <button
            className="admin-btn-main"
            onClick={(e) => popupToggle(e, true, false)}
          >
            Add
          </button>
        </div>
      )}
      <EditWindow
        type={"codes"}
        event={popup}
        close={popupToggle}
        refresh={getCodes}
        exp={props.exp}
        editMode={popupEdit}
        add={postCode}
        edit={updateCode}
      />
    </div>
  );
}
