import { useEffect, useState } from "react";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function useAxios(axiosParams, filter) {
    const [response, setResponse] = useState(undefined);

    const fetchData = async (params, filter) => {
        try {
            const result = await axios.request(params);
            setResponse(result.data[filter]);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData(axiosParams, filter);
    }, []);

    return response;
}
