import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import Loader from "../generalComponenets/Loader";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditWindow from "./PopupWindow";

export default function UserMenu(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [nameSearch, setNameSearch] = useState("");
  const searchData = useMemo(() => {
    if (nameSearch !== "") {
      return data.filter((d) =>
        d.name.toLowerCase().startsWith(nameSearch.toLowerCase())
      );
    } else {
      return data;
    }
  }, [data, nameSearch]);
  const userCount = useMemo(() => {
    try {
      return searchData.length;
    } catch (e) {
      return 0;
    }
  }, [searchData]);
  const [popup, setPopup] = useState();
  const [popupEdit, setPopupEdit] = useState(false);
  const { token } = useAuth();

  const getUsers = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/admin/user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((e) => {
        props.exp();
        console.log("Error occurred : " + e);
      });
  };

  const updateUser = (e) => {
    let userId = popup._id;
    let newAccess = document.querySelector("#admin-user-access").value;
    let newPoints = document.querySelector("#admin-user-points").value;

    let userData = JSON.stringify({
      accessLevel: newAccess,
      points: newPoints,
    });

    var config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/admin/user/${userId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: userData,
    };

    axios(config)
      .then(() => {
        getUsers();
      })
      .catch((e) => {
        props.exp();
        console.log("Error occurred : " + e);
      });
  };

  const deleteUser = (userId) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/admin/user/" + userId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        getUsers();
      })
      .catch((e) => {
        props.exp();
        console.log("Error occurred : " + e);
      });
  };

  const resetUsers = () => {
    var userData = JSON.stringify({
      masterCode: "ISSS123",
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/admin/master-reset`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: userData,
    };

    axios(config)
      .then(() => {
        getUsers();
      })
      .catch((e) => {
        props.exp();
        console.log("Error occurred : " + e);
      });
  };

  const popupToggle = (e, open = false, edit) => {
    if (edit) {
      setPopupEdit(true);
      setPopup(data.find((x) => x._id === e.target.getAttribute("data-id")));
    } else {
      setPopupEdit(false);
    }

    if (open) {
      document.querySelector(".overlay").classList.add("show");
    } else {
      document.querySelector(".overlay").classList.remove("show");

      setPopup("");

      document.querySelector("#admin-user-access").value = "";
      document.querySelector("#admin-user-points").value = "";
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div id="adminmenu-container">
      <div id="admin-topbar">
        <div id="user-stats">Users: {userCount}</div>
        <div>
          <input
            type="text"
            id="user-search"
            placeholder="Search Name..."
            onChange={(e) => setNameSearch(e.target.value)}
          />
        </div>
        <div>
          <button className="admin-btn-main" onClick={() => props.change(0)}>
            Back to Menu
          </button>
        </div>
      </div>
      {loading ? (
        <Loader style="large" />
      ) : (
        <div className="transaction-info-holder-container">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Access</TableCell>
                  <TableCell align="center">Points</TableCell>
                  <TableCell align="center">Redeemed Codes</TableCell>
                  <TableCell align="center" style={{ flex: "2" }}>
                    Transaction History
                  </TableCell>
                  <TableCell align="center">Creation Date</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">{row.accessLevel}</TableCell>
                    <TableCell align="center">{row.points}</TableCell>
                    <TableCell align="center" className="limitHeight">
                      <ol>
                        {row.redeemedCodes.map((r) => (
                          <li style={{ textAlign: "left" }}>{r}</li>
                        ))}
                      </ol>
                    </TableCell>
                    <TableCell
                      align="center"
                      className="limitHeight"
                      style={{ flex: "2" }}
                    >
                      <ol>
                        {row.transactionHistory.map((r) => (
                          <li style={{ textAlign: "left" }}>{r}</li>
                        ))}
                      </ol>
                    </TableCell>
                    <TableCell align="center">
                      <div>
                        {(() => {
                          let d = new Date(row.creationDate);

                          return `${d.getFullYear()}-${(
                            "0" +
                            (d.getMonth() + 1)
                          ).slice(-2)}-${("0" + d.getDate()).slice(-2)}`;
                        })()}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <button
                        className="admin-btn-main"
                        data-id={row._id}
                        onClick={(e) => popupToggle(e, true, true)}
                      >
                        Edit
                      </button>
                      <button
                        className="admin-btn-main dlt-btn"
                        data-id={row._id}
                        onClick={(e) =>
                          deleteUser(
                            e.target.closest("button").getAttribute("data-id")
                          )
                        }
                      >
                        Delete
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <button className="admin-btn-main" onDoubleClick={resetUsers}>
            Reset All (double click)
          </button>
        </div>
      )}
      <EditWindow
        type={"users"}
        event={popup}
        close={popupToggle}
        refresh={getUsers}
        exp={props.exp}
        editMode={popupEdit}
        edit={updateUser}
      />
    </div>
  );
}
