import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../componenets/generalComponenets/Loader";
import useAxios from "../hooks/useAxios";
import useLocalStorage from "../hooks/useLocalStorage";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [nav, setNav] = useState();
  const [user, setUser] = useState();
  const [admin, setAdmin] = useState(false);
  const [loginErr, setLoginErr] = useState();
  const [pointsHist, setPointsHist] = useState();
  const [prizeList, setPrizeList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [faq, setFaq] = useState([]);
  const [orders, setOrders] = useState([]);
  const [token, setToken] = useLocalStorage("token", "");
  const [loading, setLoading] = useState(false);

  // const pointsHist = useAxios(
  //     {
  //         method: "get",
  //         url: "/api/transactions",
  //         headers: {
  //             Authorization: `Bearer ${token}`,
  //         },
  //     },
  //     "transactions"
  // );

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      getUserInfo();
    }
  }, [user]);

  useEffect(() => {
    if (token) {
      assignUser();
    } else {
      setLoading(true);
    }
  }, []);

  const getUserInfo = async () => {
    let tempAdmin = false;

    await axios
      .get(process.env.REACT_APP_API_URL + "/login/isAdmin", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAdmin(res.data.isAdmin);
        tempAdmin = res.data.isAdmin;
      })
      .catch((e) => {
        console.log(e);
      });

    await axios
      .get(process.env.REACT_APP_API_URL + "/api/transactions", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setPointsHist(res.data["transactions"]);
      })
      .catch((e) => {
        console.log("Error occurred : " + e);
      });

    await axios
      .get(process.env.REACT_APP_API_URL + "/api/products", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setPrizeList(res.data);
      })
      .catch((e) => {
        console.log("Token is invali error is  " + e);
      });

    await axios
      .get(process.env.REACT_APP_API_URL + "/api/events", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setEventList(res.data);
      })
      .catch((e) => {
        console.log("Token is invali error is  " + e);
      });

    await axios
      .get(process.env.REACT_APP_API_URL + "/api/allTransactions", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTransactions(res.data.transactions);
      })
      .catch((e) => {
        console.log("Error occurred : " + e);
      });

    await axios
      .get(process.env.REACT_APP_API_URL + "/api/displayRedemptions", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setOrders(res.data);
      })
      .catch((e) => {
        console.log("Error occurred : " + e);
      });

    axios
      .get(process.env.REACT_APP_API_URL + "/api/faqs", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFaq(res.data);
      })
      .catch((e) => {
        console.log("Error occurred : " + e);
      });

    setLoading(true);
    if (nav) {
      if (tempAdmin && window.location.pathname === "/login") {
        navigate("/admin", { replace: true });
        setNav(false);
      } else {
        if (user.accessLevel === "volunteer") {
          navigate("/volunteer", { replace: true });
        } else {
          navigate("/", { replace: true });
        }
        setNav(false);
      }
    }
  };

  const assignUser = async (nav = false, useToken = token) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/login/status", {
        headers: { Authorization: `Bearer ${useToken}` },
      })
      .then((res) => {
        setUser(res.data);
        if (nav) {
          setNav(true);
        }
      })
      .catch((e) => {
        setUser(null);
        setToken("");
        setLoading(true);
      });
  };

  const login = async (googleData) => {
    setLoginErr("");
    const res = await fetch(
      process.env.REACT_APP_API_URL + "/login/google/oauth",
      {
        method: "POST",
        body: JSON.stringify({
          token: googleData.credential,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    if (data.loginValid) {
      setToken(data.jtoken);
      assignUser(true, data.jtoken);
    } else {
      setLoginErr(data.message);
    }
  };

  const logout = () => {
    setUser(null);
    setToken("");
  };

  const checkLogin = () => {
    if (user) {
      navigate("../", { replace: true });
    }
  };

  const update = async () => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/login/status", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data.loggedIn) {
          setUser(res.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const value = {
    user,
    admin,
    token,
    loginErr,
    pointsHist,
    prizeList,
    eventList,
    transactions,
    orders,
    faq,
    login,
    logout,
    update,
    checkLogin,
    setLoading,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? children : <Loader style="large" />}
    </AuthContext.Provider>
  );
}
