import "./SurveyCard.css";

const SurveyCard = (props) => {
    return (
        <div className="survey-card">
            <img
                src={props.image}
                className="survey-card-img"
                alt="survey-icon"
            />
            <div className="survey-card-info">
                <div className="survey-card-main-info">
                    <div className="survey-card-name">{props.name}</div>
                    <div className="survey-card-secondary">
                        <div className="survey-card-date">{props.date}</div>
                        <div className="survey-card-points">
                            {props.points} ISSSicles
                        </div>
                    </div>
                </div>
                <div className="survey-card-description">
                    {props.description}
                </div>
                <a
                    href={props.link}
                    // target="_blank"
                    // rel="noreferrer"
                    className="survey-card-btn"
                    // onClick={() => (window.location.href = props.link)}
                >
                    Begin Survey
                </a>
            </div>
        </div>
    );
};

export default SurveyCard;
