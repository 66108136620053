import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import Loader from "../generalComponenets/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";

export default function InquiryMenu(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [inquirySearch, setInquirySearch] = useState("");
  const searchData = useMemo(() => {
    if (inquirySearch !== "") {
      return data.filter((d) =>
        d.name.toLowerCase().startsWith(inquirySearch.toLowerCase())
      );
    } else {
      return data;
    }
  }, [data, inquirySearch]);
  const inquiryCount = useMemo(() => {
    try {
      return searchData.length;
    } catch (e) {
      return 0;
    }
  }, [searchData]);
  const { token } = useAuth();

  const getInquiries = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/admin/inquiry", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((e) => {
        props.exp();
        console.log("Error occurred : " + e);
      });
  };

  const completeInquiry = (inquiryId) => {
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/admin/inquiry/${inquiryId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(() => {
        getInquiries();
      })
      .catch((e) => {
        props.exp();
        console.log("Error occurred : " + e);
      });
  };

  useEffect(() => {
    getInquiries();
  }, []);

  return (
    <div id="adminmenu-container">
      <div id="admin-topbar">
        <div id="user-stats">Inquiries: {inquiryCount}</div>
        <div>
          <input
            type="text"
            id="user-search"
            placeholder="Search Name..."
            onChange={(e) => setInquirySearch(e.target.value)}
          />
        </div>
        <div>
          <button className="admin-btn-main" onClick={() => props.change(0)}>
            Back to Menu
          </button>
        </div>
      </div>
      {loading ? (
        <Loader style="large" />
      ) : (
        <div className="transaction-info-holder-container">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Subject</TableCell>
                  <TableCell align="center">Message</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">{row.subject}</TableCell>
                    <TableCell align="left">{row.message}</TableCell>
                    <TableCell align="center">
                      {(() => {
                        let d = new Date(row.date);

                        return `${d.getFullYear()}-${(
                          "0" +
                          (d.getMonth() + 1)
                        ).slice(-2)}-${("0" + d.getDate()).slice(-2)}`;
                      })()}
                    </TableCell>
                    <TableCell align="center">
                      <button
                        className="admin-btn-main"
                        data-id={row._id}
                        onClick={(e) => completeInquiry(row._id)}
                        disabled={row.resolved}
                      >
                        {row.resolved ? "Resolved" : "Resolve"}
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}
