import "./HamburgerMenu.css";

const HamburgerMenu = (props) => {
  return (
    <div className="hamburger-menu-container">
      <button className="hamburger-btn" onClick={props.click}>
        {/* <img
          src={require("../../assets/images/general/more.png")}
          alt="hamburger menu"
          id="hamburger-icon"
        /> */}
      </button>
    </div>
  );
};

export default HamburgerMenu;
