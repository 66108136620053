import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import "./Dropdown.css";

const Dropdown = (props) => {
  const { user, pointsHist } = useAuth();
  let linkClick = useOutletContext();
  const [show, setShow] = useState(false);
  // const [pointsHist, setPointsHist] = useState(props.hist);

  // const viewTransactionHistory = () => {
  //     axios
  //         .get(process.env.REACT_APP_API_URL + "/api/transactions", {
  //             headers: {
  //                 Authorization: `Bearer ${localStorage.getItem("token")}`,
  //             },
  //         })
  //         .then((res) => {
  //             setPointsHist(res.data["transactions"]);
  //         })
  //         .catch((e) => {
  //             console.log("Error occurred : " + e);
  //         });
  // };

  const generateHistory = () => {
    let toReturn = [];

    if (!pointsHist) {
      return;
    }

    if (pointsHist.length === 0) {
      toReturn.push(
        <h4 className="no-events-label" key="sdgsgsdgasefsxcz">
          Wow, such empty
        </h4>
      );
    }

    for (let i = pointsHist.length - 1; i >= 0; i--) {
      let currentHist = pointsHist[i].split(";");

      toReturn.push(
        <div className="point-transaction" key={i}>
          <div className="point-transaction-left">
            <div className="point-transaction-amount">{currentHist[0]}</div>
            <div className="point-transaction-reason">{currentHist[1]}</div>
          </div>
          <div className="point-transaction-date">{currentHist[2]}</div>
        </div>
      );
    }

    return (
      <div className="dropdown-body">
        {toReturn}
        <div className="points-issue">
          <Link
            to="/contact"
            state={{ subject: "Missing Points" }}
            id="points-issue-link"
            onClick={linkClick}
          >
            Missing points?
          </Link>
        </div>
      </div>
    );
  };

  useEffect(() => {
    document
      .querySelector(".dropdwn-points-container")
      .classList.toggle("show");
    document.querySelector(".dropdown-img").classList.toggle("show");
  }, [show]);

  // useEffect(() => {
  //     viewTransactionHistory();
  // }, [user.points]);

  return (
    <div className="dropdown-container">
      <div className="dropdown-header" onClick={() => setShow(!show)}>
        <div className="dropdown-title">Points History</div>
        <button className="dropdown-btn">
          <img
            src={require("../../assets/images/general/arrow-down.png")}
            alt="drop down"
            className="dropdown-img"
          />
        </button>
      </div>
      {generateHistory()}
    </div>
  );
};

export default Dropdown;
