import { useEffect } from "react";
import "./Popup.css";

const Popup = (props) => {
    const getBody = () => {
        let toReturn = [];

        for (let i = 0; i < props.body.length; i++) {
            toReturn.push(
                <div className="popup-para" key={i}>
                    {props.body[i]}
                </div>
            );
        }

        return <div className="popup-body">{toReturn}</div>;
    };

    return (
        <div className="popup">
            <div className="popup-btn-container">
                <div className="popup-header">
                    Terms of Use & Privacy Notice
                </div>
                {props.hasAccepted && (
                    <button className="popup-btn" onClick={props.click}>
                        ✕
                    </button>
                )}
            </div>
            {getBody()}
            {!props.hasAccepted && (
                <div className="popup-accept-message">
                    To proceed you must read and accept the TOS
                    <button className="popup-accept-btn" onClick={props.accept}>
                        Accept
                    </button>
                </div>
            )}
        </div>
    );
};

export default Popup;
