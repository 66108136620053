import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import { toast } from "react-toastify";

export default function EditWindow(props) {
  const [choice, setChoice] = useState("");

  const typeController = () => {
    if (props.type === "users") {
      return populateUser();
    } else if (props.type === "codes") {
      return populateCode();
    } else if (props.type === "prizes") {
      return populatePrizes();
    } else if (props.type === "events") {
      return populateEvent();
    } else if (props.type === "faq") {
      return populateFaq();
    }
  };

  const populateUser = () => {
    return (
      <div className="edit-input-container">
        <div>
          <label>Access</label>
          <select id="admin-user-access">
            <option value="user">User</option>
            <option value="volunteer">Volunteer</option>
            <option value="admin">Admin</option>
          </select>
        </div>
        <div>
          <label>Points</label>
          <input type="number" id="admin-user-points" />
        </div>
      </div>
    );
  };

  const populateCode = () => {
    return (
      <div className="edit-input-container">
        <div>
          <label>Code Type</label>
          <select
            id="admin-code-type"
            disabled={props.editMode}
            onChange={(e) => {
              setChoice(e.target.value);
            }}
          >
            <option selected="true" style={{ display: "none" }}>
              Select...
            </option>
            <option value="Static">Static</option>
            <option value="Dynamic">Dynamic</option>
          </select>
        </div>
        {choice === "Static" && (
          <div>
            <label>Code</label>
            <input type="text" id="admin-code-name" />
          </div>
        )}
        {choice === "Static" && (
          <div>
            <label>Points</label>
            <input type="number" id="admin-code-points" />
          </div>
        )}
        {choice === "Static" && (
          <div>
            <label>Description</label>
            <textarea type="text" id="admin-code-description" />
          </div>
        )}
        {choice === "Static" && (
          <div>
            <label>Code Use</label>
            <input type="number" defaultValue={-1} id="admin-code-use" />
          </div>
        )}
        {(choice === "Static" || choice === "Dynamic") && (
          <div>
            <label>Valid From</label>
            <input
              type="datetime-local"
              defaultValue={-1}
              id="admin-code-valid-from"
            />
          </div>
        )}
        {(choice === "Static" || choice === "Dynamic") && (
          <div>
            <label>Valid Until</label>
            <input
              type="datetime-local"
              defaultValue={-1}
              id="admin-code-valid-until"
            />
          </div>
        )}
      </div>
    );
  };

  const populatePrizes = () => {
    return (
      <div className="edit-input-container">
        <div>
          <label>Prize Name</label>
          <input type="text" id="admin-prize-name" />
        </div>
        <div>
          <label>Points</label>
          <input type="number" id="admin-prize-points" />
        </div>
        <div>
          <label>Description</label>
          <textarea type="text" id="admin-prize-description" />
        </div>
        <div>
          <label>Brief</label>
          <input type="text" id="admin-prize-brief" />
        </div>
        <div>
          <label>Image Url</label>
          <input type="text" id="admin-prize-image" />
        </div>
      </div>
    );
  };

  const populateEvent = () => {
    return (
      <div className="edit-input-container">
        <div>
          <label>Event Name</label>
          <input type="text" id="admin-event-name" />
        </div>
        <div>
          <label>Points Reward</label>
          <input type="number" id="admin-event-points" />
        </div>
        <div>
          <label>Description</label>
          <textarea type="text" id="admin-event-description" />
        </div>
        <div>
          <label>Start Date</label>
          <input type="datetime-local" id="admin-event-sdate" />
        </div>
        <div>
          <label>End Date</label>
          <input type="datetime-local" id="admin-event-edate" />
        </div>
        <div>
          <label>Location</label>
          <input type="text" id="admin-event-location" />
        </div>
        <div>
          <label>Image Url</label>
          <input type="text" id="admin-event-image" />
        </div>
        <div>
          <label>Event Type</label>
          <select
            id="admin-event-type"
            onChange={(e) => {
              setChoice(e.target.value);
            }}
          >
            <option value="Event">Event</option>
            <option value="Survey">Survey</option>
          </select>
        </div>
        {choice === "Survey" && (
          <div>
            <label>Survey Link</label>
            <input type="text" id="admin-event-image" />
          </div>
        )}
      </div>
    );
  };

  const populateFaq = () => {
    return (
      <div className="edit-input-container">
        <div>
          <label>Question</label>
          <input type="text" id="admin-faq-question" />
        </div>
        <div>
          <label>Answer</label>
          <textarea type="text" id="admin-faq-answer" />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (props.editMode) {
      if (props.type === "users") {
        console.log(props.event?.accessLevel);
        try {
          document.querySelector("#admin-user-access").value =
            props.event?.accessLevel;
          document.querySelector("#admin-user-points").value =
            props.event?.points;
        } catch (e) {}
      } else if (props.type === "codes") {
        try {
          document.querySelector("#admin-code-type").value = "Static";
          setChoice("Static");
          document.querySelector("#admin-code-name").value = props.event?.code;
          document.querySelector("#admin-code-points").value =
            props.event?.pointAmount;
          document.querySelector("#admin-code-description").value =
            props.event?.description;
          document.querySelector("#admin-code-use").value =
            props.event?.codesUses;
          document.querySelector("#admin-code-valid-from").value =
            props.event?.validFrom.slice(0, -1);
          document.querySelector("#admin-code-valid-until").value =
            props.event?.validUntil.slice(0, -1);
        } catch (e) {}
      } else if (props.type === "prizes") {
        try {
          document.querySelector("#admin-prize-name").value = props.event?.name;
          document.querySelector("#admin-prize-points").value =
            props.event?.pointCost;
          document.querySelector("#admin-prize-description").value =
            props.event?.longDescription;
          document.querySelector("#admin-prize-brief").value =
            props.event?.shortDescription;
          document.querySelector("#admin-prize-image").value =
            props.event?.image;
        } catch (e) {}
      } else if (props.type === "events") {
        try {
          document.querySelector("#admin-event-name").value = props.event?.name;
          document.querySelector("#admin-event-points").value =
            props.event?.pointReward;
          document.querySelector("#admin-event-description").value =
            props.event?.description;
          document.querySelector("#admin-event-image").value =
            props.event?.imgUrl;
          document.querySelector("#admin-event-type").value =
            props.event?.eventType;
          document.querySelector("#admin-event-location").value =
            props.event?.location;

          let sd = new Date(props.event?.startDate);
          document.querySelector(
            "#admin-event-sdate"
          ).value = `${sd.getFullYear()}-${("0" + (sd.getMonth() + 1)).slice(
            -2
          )}-${("0" + sd.getDate()).slice(-2)}T${("0" + sd.getHours()).slice(
            -2
          )}:${("0" + sd.getMinutes()).slice(-2)}:${(
            "0" + sd.getSeconds()
          ).slice(-2)}.${"" + sd.getMilliseconds()}`;

          let ed = new Date(props.event?.endDate);
          document.querySelector(
            "#admin-event-edate"
          ).value = `${ed.getFullYear()}-${("0" + (ed.getMonth() + 1)).slice(
            -2
          )}-${("0" + ed.getDate()).slice(-2)}T${("0" + ed.getHours()).slice(
            -2
          )}:${("0" + ed.getMinutes()).slice(-2)}:${(
            "0" + ed.getSeconds()
          ).slice(-2)}.${"" + ed.getMilliseconds()}`;
        } catch (error) {}
      } else if (props.type === "faq") {
        try {
          document.querySelector("#admin-faq-question").value =
            props.event?.title;
          document.querySelector("#admin-faq-answer").value = props.event?.body;
        } catch (e) {}
      }
    }
  }, [props.event]);

  return (
    <div className="overlay">
      <div id="editContainer-outer">
        <div id="editContainer-inner">
          <button
            type="button"
            class="admin-popup-close-btn"
            onClick={(e) => {
              setChoice("");
              props.close();
            }}
          >
            ✕
          </button>
          {typeController()}
          <button
            className="popup-edit-btn"
            onClick={
              props.editMode
                ? () => {
                    if (props.edit()) {
                      setChoice("");
                      document.querySelector("#admin-code-type").value = "";
                    }
                  }
                : () => {
                    if (props.add()) {
                      setChoice("");
                      document.querySelector("#admin-code-type").value = "";
                    }
                  }
            }
          >
            {props.editMode ? "Edit" : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
}
