import "./Contact.css";

import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import Loader from "../componenets/generalComponenets/Loader";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const emailSub = location.state;
  const subj = emailSub.subject;

  const contact = () => {
    if (
      document.querySelector("#subjectField").value !== "" &&
      document.querySelector("#messageField").value !== ""
    ) {
      setLoading(true);
      axios
        .post(
          process.env.REACT_APP_API_URL + "/api/submitInquiry",
          {
            subject: document.querySelector("#subjectField").value,
            message: document.querySelector("#messageField").value,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          document.querySelector("#subjectField").value = "";
          document.querySelector("#messageField").value = "";
          toast.success(res.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((res) => {
          setLoading(false);
          toast.error("Something went wrong", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      toast.error(
        "Please make sure both Subject and Message fields are filled",
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  return (
    <div className="contact-container">
      <form className="contact-form">
        <div className="label-input-container">
          <label htmlFor="subjectField">Subject:</label>
          <input
            type="text"
            id="subjectField"
            name="subjectField"
            autoComplete="off"
            defaultValue={subj}
          ></input>
        </div>
        <div className="label-input-container">
          <label htmlFor="messageField">Message:</label>
          <textarea
            id="messageField"
            name="messageField"
            cols="10"
            rows="5"
          ></textarea>
        </div>
        <div className="contact-submit-btn-container">
          <button
            type="button"
            className="contact-submit-btn"
            onClick={contact}
            disabled={loading}
          >
            {loading ? <Loader /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
