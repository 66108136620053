import "./EventCard.css";

const EventCard = (props) => {
  const getDate = () => {
    // if
    let sd = new Date(props.startDate);
    let ed = new Date(props.endDate);

    if (props.startDate.split("T")[0] === props.endDate.split("T")[0]) {
      return (
        <div className="event-card-date">
          {sd.getFullYear()}-{("0" + (sd.getMonth() + 1)).slice(-2)}-
          {("0" + sd.getDate()).slice(-2)}{" "}
          <div>
            {("0" + sd.getHours()).slice(-2)}:
            {("0" + sd.getMinutes()).slice(-2)} -{" "}
            {("0" + ed.getHours()).slice(-2)}:
            {("0" + ed.getMinutes()).slice(-2)}
          </div>
        </div>
      );
    } else {
      return (
        <div className="event-card-date">
          {sd.getFullYear()}-{("0" + (sd.getMonth() + 1)).slice(-2)}-
          {("0" + sd.getDate()).slice(-2)} {("0" + sd.getHours()).slice(-2)}:
          {("0" + sd.getMinutes()).slice(-2)}
          <div>-</div>
          <div>
            {ed.getFullYear()}-{("0" + (ed.getMonth() + 1)).slice(-2)}-
            {("0" + ed.getDate()).slice(-2)} {("0" + ed.getHours()).slice(-2)}:
            {("0" + ed.getMinutes()).slice(-2)}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="event-card">
      <img src={props.image} className="event-card-img" alt="event-icon" />
      <div className="event-card-info">
        <div className="event-card-main-info">
          <div className="event-card-name">{props.name}</div>
          <div className="event-card-secondary">{getDate()}</div>
        </div>
        <div className="event-card-description">
          <div>
            <b>Location: </b>
            {props.location}
          </div>
          <div className="event-card-points">
            <b>Points: </b>
            {props.points} ISSSicles
          </div>
          {props.description}
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href={props.link}
          className="event-card-btn"
        >
          Save Event
        </a>
      </div>
    </div>
  );
};

export default EventCard;
