import "./Login.css";

import Popup from "../componenets/loginComponents/Popup";
import GoogleLoginBtn from "../componenets/loginComponents/GoogleLoginBtn";
import { useAuth } from "../contexts/AuthContext";
import useLocalStorage from "../hooks/useLocalStorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import Loader from "../componenets/generalComponenets/Loader";

function Login() {
  const { login, checkLogin, loginErr } = useAuth();
  const [loading, setLoading] = useState(false);
  const [accepted, setAccepted] = useLocalStorage("tosAccept", false);

  const handleLogin = async (googleData) => {
    setLoading(true);
    await login(googleData);
  };

  const handleFailure = (response) => {
    // console.log(response);
    toast.warning(response.message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setLoading(false);
  };

  const toggleShow = () => {
    document.querySelector(".popup").classList.toggle("show");
    document.querySelector(".backdrop").classList.toggle("show");
  };

  const showPop = () => {
    document.querySelector(".popup").classList.add("show");
    document.querySelector(".backdrop").classList.add("show");
  };

  const accept = () => {
    setAccepted(true);
    toggleShow();
    setLoading(false);
  };

  useEffect(() => {
    if (loginErr) {
      toast.error(loginErr, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  }, [loginErr]);

  useEffect(() => {
    // if (
    //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //     navigator.userAgent
    //   )
    // ) {
    //   document.querySelector(".login-body").classList.add("mobile");
    // }
    checkLogin();
    if (accepted === "" || accepted === "false" || accepted === false) {
      setLoading(true);
      showPop();
    }
  }, []);

  return (
    <div className="login-body">
      <div className="backdrop"></div>
      <div className="login-container">
        <img
          src={require("../assets/images/general/logo.png")}
          alt="ISSS logo"
          className="login-logo-icon"
        />
        <div className="bottom-container">
          {/* <div id="ualberta-reminder">
                        Please login with your ualberta email
                    </div> */}
          <div className="google-container">
            {!loading ? (
              <GoogleLoginBtn success={handleLogin} failure={handleFailure} />
            ) : (
              <button id="google-login-loader" disabled={true}>
                <Loader style="google" />
              </button>
            )}
            <div id="disclaimer">
              By logging in you accept our TOS
              {/* <button id="tos-btn" onClick={toggleShow}>
                                TOS
                            </button> */}
            </div>
          </div>
          <div className="links-container">
            <div className="tos-container">
              <button
                type="button"
                onClick={toggleShow}
                className="links"
                id="links-btn"
              >
                Terms of Use & Privacy Notice
              </button>
            </div>
            <div className="isss-container">
              <a href="https://isss.ca/" className="links">
                ISSS
              </a>
              <a href="https://isss.ca/sci5-faq/" className="links">
                Sci5
              </a>
            </div>
          </div>
        </div>
      </div>
      <Popup
        identity="tos"
        hasAccepted={accepted.toString() === "true"}
        click={toggleShow}
        accept={accept}
        header="Terms of Use & Privacy Notice"
        body={[
          "By using ISSSRewards you allow the Interdepartmental Science Students' Society to use your name, email address, and Instagram handle in order to create and manage your personal ISSSRewards account.",
          "By having an ISSSRewards account you will be permitted to collect points (ISSSicles) as part of the program, whether through attending events, completing surveys, engaging with our social media, or other means. You will also be permitted to redeem these points for rewards once they become available.",
          "Your personally identifiable information is used only for authentication and account management and will never be shared with any third party.",
          <b>
            This service is only available to current undergraduate science
            students at the University of Alberta, students of other faculties
            may participate but will not recieve a prize.
          </b>,
          "** If you have any further questions or concerns please reach out to marketing@isss.ca.",
        ]}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Login;
