import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import Loader from "../generalComponenets/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditWindow from "./PopupWindow";
import { toast } from "react-toastify";

export default function RedemptionMenu(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [redemptionSearch, setRedemptionSearch] = useState("");
  const searchData = useMemo(() => {
    if (redemptionSearch !== "") {
      return data.filter((d) =>
        d.user.name.toLowerCase().startsWith(redemptionSearch.toLowerCase())
      );
    } else {
      return data;
    }
  }, [data, redemptionSearch]);
  const redemptionCount = useMemo(() => {
    try {
      return searchData.length;
    } catch (e) {
      return 0;
    }
  }, [searchData]);
  const { token } = useAuth();

  const getRedemptions = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/admin/redemptions", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((e) => {
        props.exp();
        console.log("Error occurred : " + e);
      });
  };

  const completeRedemptions = (redemptionId) => {
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/admin/redemptions/${redemptionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(() => {
        getRedemptions();
      })
      .catch((e) => {
        props.exp();
        console.log("Error occurred : " + e);
      });
  };

  const populateBody = () => {
    let toReturn = [];

    for (let i = 0; i < data.length; i++) {
      toReturn.push(
        <tr key={i}>
          <td>{data[i].user ? data[i].user.name : "Deleted User"}</td>
          <td>{data[i].user ? data[i].user.email : "Deleted User"}</td>
          <td>
            {data[i].product.name} x {data[i].quantity}
          </td>
          <td>{data[i].date.split("T")[0]}</td>
          <td>{data[i].fulfilled ? "True" : "False"}</td>
          <td>
            <button
              className="admin-btn-main check-btn"
              data-id={data[i]._id}
              onClick={(e) =>
                completeRedemptions(
                  e.target.closest("button").getAttribute("data-id")
                )
              }
            >
              <img
                className="admin-dlt-img"
                src={require("../../assets/images/general/checked.png")}
                alt="delete-icon"
              />
            </button>
          </td>
        </tr>
      );
    }

    return <tbody>{toReturn}</tbody>;
  };

  useEffect(() => {
    getRedemptions();
  }, []);

  return (
    <div id="adminmenu-container">
      <div id="admin-topbar">
        <div id="user-stats">Redemptions: {redemptionCount}</div>
        <div>
          <input
            type="text"
            id="user-search"
            placeholder="Search Name..."
            onChange={(e) => setRedemptionSearch(e.target.value)}
          />
        </div>
        <div>
          <button className="admin-btn-main" onClick={() => props.change(0)}>
            Back to Menu
          </button>
        </div>
      </div>
      {loading ? (
        <Loader style="large" />
      ) : (
        <div className="transaction-info-holder-container">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Product</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{row.user.name}</TableCell>
                    <TableCell align="center">{row.user.email}</TableCell>
                    <TableCell align="center">
                      {row.product.name} x {row.quantity}
                    </TableCell>
                    <TableCell align="center">
                      {row.date.split("T")[0]}
                    </TableCell>
                    <TableCell align="center">
                      <button
                        className="admin-btn-main"
                        data-id={row._id}
                        onClick={(e) => completeRedemptions(row._id)}
                        disabled={row.fulfilled}
                      >
                        {row.fulfilled ? "Fulfilled" : "Fulfill"}
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}
