import React, { useEffect, useState } from "react";
import "./Volunteer.css";
import QRCode from "qrcode";

export default function Volunteer() {
  const [qr, setQr] = useState();
  const [code, setCode] = useState("SAMPLECODE");

  useEffect(() => {
    QRCode.toDataURL(code, function (err, url) {
      setQr(url);
    });
  }, [code]);

  return (
    <div id="volunteer-container">
      <div id="volunteer-points-container">
        <img src={qr} alt="qr code" id="volunteer-points-qr-img" />
        <div id="volunteer-points-qr-code">{code}</div>
      </div>
    </div>
  );
}
