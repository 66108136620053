import "./Home.css";

import Dropdown from "../componenets/homeComponents/Dropdown";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../contexts/AuthContext";
import { useState } from "react";
import Loader from "../componenets/generalComponenets/Loader";
import QRScanner from "../componenets/homeComponents/QRScanner";

const Home = () => {
  const { user, update, token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showScanner, setShowScanner] = useState(false);

  const addPoints = (
    e,
    myCode = document.querySelector("#point-input-field").value
  ) => {
    if (myCode) {
      if (showScanner) myCode = myCode.text;
      setLoading(true);

      axios
        .post(
          process.env.REACT_APP_API_URL + "/api/redeemCode",
          {
            codeName: myCode,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          if (showScanner) {
            toggleScanner();
          }
          if (res.data.valid) {
            update();
            toast.success(res.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.warning(res.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((res) => {
          if (showScanner) {
            toggleScanner();
          }
          setLoading(false);
          // context[3](localStorage.getItem("token"));
          toast.error("Something went wrong", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // response = res.message;
        });

      document.querySelector("#point-input-field").value = "";
    }
  };

  const toggleScanner = () => {
    setShowScanner(!showScanner);
  };

  return (
    <div className="home-container">
      {showScanner && <div id="home-backdrop"></div>}
      {showScanner && <QRScanner toggle={toggleScanner} scanned={addPoints} />}
      <div className="points-container">
        <div className="points-display-container isssicle-container">
          <div className="points-display-points">{user.points}</div>
        </div>
        <div className="point-input-container">
          <input
            type="text"
            id="point-input-field"
            className="points-display-container"
            placeholder="Enter your code"
            name="codeName"
            autoComplete="off"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addPoints();
              }
            }}
          />
          <div id="point-btn-container">
            <button
              type="button"
              className="point-btn"
              onClick={addPoints}
              disabled={loading}
            >
              {loading ? <Loader /> : "Submit"}
            </button>
            {/* <button
              type="button"
              className="point-btn"
              id="point-btn-qr"
              onClick={() => setShowScanner(true)}
            >
              <img
                src={require("../assets/images/general/qr.png")}
                alt="qr scanner"
                id="point-qr-scanner-img"
              />
            </button> */}
          </div>
        </div>
      </div>
      <div className="points-container dropdwn-points-container">
        <Dropdown />
      </div>
    </div>
  );
};

export default Home;
