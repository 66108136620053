import { useEffect, useState } from "react";
import "./PrizeInfoPopup.css";
import { toast } from "react-toastify";
import Loader from "../generalComponenets/Loader";

const PrizeInfoPopup = (props) => {
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(props.cost);
  const [delivery, setDelivery] = useState();
  const [loading, setLoading] = useState(false);

  const closeWindow = () => {
    // if (delivery === "ship") {
    //   document.querySelector("#shipping-address").value = "";
    //   document.querySelector("#shipping-city").value = "";
    //   document.querySelector("#shipping-province").value = "";
    //   document.querySelector("#shipping-postal").value = "";
    //   document.querySelector("#shipping-phone").value = "";
    // }

    setDelivery("");
    props.click();
  };

  const purchase = () => {
    if (loading) {
      return;
    }
    if (quantity % 1 !== 0) {
      toast.error("Invalid quantity", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (delivery === "ship") {
      if (
        !document.querySelector("#shipping-address").value ||
        !document.querySelector("#shipping-city").value ||
        !document.querySelector("#shipping-province").value ||
        !document.querySelector("#shipping-postal").value ||
        !document.querySelector("#shipping-phone").value
      ) {
        toast.error("Please fill in all the shipping information", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      const shippingData = {
        address: document.querySelector("#shipping-address").value,
        city: document.querySelector("#shipping-city").value,
        province: document.querySelector("#shipping-province").value,
        country: "null",
        postal: document.querySelector("#shipping-postal").value,
        phone: document.querySelector("#shipping-phone").value,
      };
      props.redeem(
        props.id,
        quantity,
        setLoading,
        " we will email you when everything has been processed",
        shippingData
      );
    } else {
      props.redeem(
        props.id,
        quantity,
        setLoading,
        " we will email you when it is ready to pick up"
      );
    }
  };

  const changeQuant = (e) => {
    setQuantity(e.target.value);
  };

  useEffect(() => {
    setPrice(props.price * quantity);
  }, [quantity]);

  useEffect(() => {
    if (delivery === "ship") {
      document.querySelector("#prize-popup-delivery-pickup").checked = false;
    } else if (delivery === "pickup") {
      document.querySelector("#prize-popup-delivery-ship").checked = false;
    } else {
      document.querySelector("#prize-popup-delivery-ship").checked = false;
      document.querySelector("#prize-popup-delivery-pickup").checked = false;
    }
  }, [delivery]);

  useEffect(() => {
    document.querySelector(".prize-popup-redeem-input").value = "1";
    setQuantity(1);
    setPrice(props.price * quantity);
  }, [props]);

  return (
    <div className="prize-popup">
      <div className="prize-popup-img">
        <img src={props.image} alt="product" />
      </div>
      <div className="prize-popup-right-container">
        <button
          type="button"
          className="prize-popup-close-btn"
          onClick={closeWindow}
        >
          ✕
        </button>
        <div className="prize-popup-details">
          <div className="prize-popup-info-container">
            <div className="prize-popup-header">
              <div className="prize-popup-name">{props.name}</div>
              {/* <div className="prize-popup-short-description">
                <a href={props.shortDesc}>Product Link</a>
              </div> */}
            </div>

            <div className="prize-popup-description">
              <div
                id="prize-popup-delivery-method"
                onChange={(e) => setDelivery(e.target.value)}
              >
                <span style={{ display: "flex" }}>
                  <input
                    type="radio"
                    id="prize-popup-delivery-ship"
                    name="shipping_method"
                    value="ship"
                    disabled={!props.shippable}
                  ></input>
                  <label htmlFor="prize-popup-delivery-ship">Ship</label>
                </span>
                <span style={{ display: "flex" }}>
                  <input
                    type="radio"
                    id="prize-popup-delivery-pickup"
                    name="shipping_method"
                    value="pickup"
                  ></input>
                  <label htmlFor="prize-popup-delivery-pickup">Pickup</label>
                </span>
              </div>
              <div id="prize-popup-delivery-details">
                {delivery === "ship" && (
                  <div id="prize-popup-delivery-ship">
                    <div className="prize-popup-delivery-ship-subcontainer">
                      <label htmlFor="prize-popup-delivery-ship-address">
                        Address:
                      </label>
                      <input
                        type="text"
                        name="prize-popup-delivery-ship-address"
                        id="shipping-address"
                        className="prize-popup-delivery-ship-input"
                        required
                      ></input>
                    </div>
                    <div className="prize-popup-delivery-ship-subcontainer">
                      <label htmlFor="prize-popup-delivery-ship-city">
                        City:
                      </label>
                      <input
                        type="text"
                        name="prize-popup-delivery-ship-city"
                        className="prize-popup-delivery-ship-input"
                        id="shipping-city"
                        style={{ width: "100%" }}
                      ></input>
                    </div>
                    <div className="prize-popup-delivery-ship-subcontainer">
                      <label htmlFor="prize-popup-delivery-ship-province">
                        Province:
                      </label>
                      <input
                        type="text"
                        name="prize-popup-delivery-ship-province"
                        className="prize-popup-delivery-ship-input"
                        id="shipping-province"
                        style={{ width: "100%" }}
                      ></input>
                    </div>
                    <div className="prize-popup-delivery-ship-subcontainer">
                      <label htmlFor="prize-popup-delivery-ship-postal">
                        Postal:
                      </label>
                      <input
                        type="text"
                        name="prize-popup-delivery-ship-postal"
                        className="prize-popup-delivery-ship-input "
                        id="shipping-postal"
                        style={{ width: "100%" }}
                      ></input>
                    </div>
                    <div className="prize-popup-delivery-ship-subcontainer">
                      <label htmlFor="prize-popup-delivery-ship-phone">
                        Phone:
                      </label>
                      <input
                        type="tel"
                        name="prize-popup-delivery-ship-phone"
                        className="prize-popup-delivery-ship-input"
                        id="shipping-phone"
                      ></input>
                    </div>
                  </div>
                )}
                {delivery === "pickup" && (
                  <div className="prize-popup-delivery-pickup">
                    Once your product is ready to pickup we will email you and
                    you can pick it up at CCIS 1-150.
                  </div>
                )}
              </div>
            </div>
            <div className="prize-popup-cost-container">
              <span>
                <label htmlFor="quantity-prize-input">Quantity:</label>
                <input
                  name="quantity-prize-input"
                  type="number"
                  className="prize-popup-redeem-input"
                  min={1}
                  defaultValue={quantity}
                  onChange={changeQuant}
                />
              </span>
              <div className="prize-popup-price">{price} ISSSicles</div>
            </div>
          </div>
          <div className="prize-popup-redeem-container">
            <button
              type="button"
              className="prize-popup-redeem-btn"
              onClick={purchase}
              disabled={!delivery}
            >
              {loading ? <Loader style="prize" /> : "Redeem"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrizeInfoPopup;
