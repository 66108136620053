import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/index.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";

import Login from "./pages/Login";
import Navbar from "./pages/Navbar";
import Home from "./pages/Home";
import Prizes from "./pages/Prizes";
import Events from "./pages/Events";
import User from "./pages/User";
import FAQ from "./pages/FAQ";
import Contact from "./pages/Contact";
import Admin from "./pages/Admin";
import Volunteer from "./pages/Volunteer";
import axios from "axios";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./componenets/PrivateRoute";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<PrivateRoute access="user" />}>
          <Route path="/" element={<Navbar />}>
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="prizes" element={<Prizes />} />
            <Route path="events" element={<Events />} />
            <Route path="user" element={<User />} />
            <Route path="FAQ" element={<FAQ />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<Navigate replace to={"/"} />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<PrivateRoute access="admin" />}>
          <Route path="/admin" element={<Admin />} />
        </Route>
        <Route path="/volunteer" element={<PrivateRoute access="volunteer" />}>
          <Route path="/volunteer" element={<Volunteer />} />
        </Route>
      </Routes>
    </AuthProvider>
  </BrowserRouter>
);
