import "./User.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const User = () => {
  const { user, transactions, orders } = useAuth();

  // const [transactions, setTransactions] = useState([]);
  // const [orders, setOrders] = useState([]);

  // const getAllTransactions = () => {
  //     axios
  //         .get(process.env.REACT_APP_API_URL + "/api/allTransactions", {
  //             headers: {
  //                 Authorization: `Bearer ${localStorage.getItem("token")}`,
  //             },
  //         })
  //         .then((res) => {
  //             setTransactions(res.data.transactions);
  //         })
  //         .catch((e) => {
  //             console.log("Error occurred : " + e);
  //         });
  // };

  // const getAllOrders = () => {
  //     axios
  //         .get(process.env.REACT_APP_API_URL + "/api/displayRedemptions", {
  //             headers: {
  //                 Authorization: `Bearer ${localStorage.getItem("token")}`,
  //             },
  //         })
  //         .then((res) => {
  //             setOrders(res.data);
  //         })
  //         .catch((e) => {
  //             console.log("Error occurred : " + e);
  //         });
  // };

  // useEffect(() => {
  //     getAllTransactions();
  //     getAllOrders();
  // }, []);

  const generateAllTransactions = () => {
    let toReturn = [];

    for (let i = transactions.length - 1; i >= 0; i--) {
      console.log(transactions[i]);
      let transInfo = transactions[i].split(";");
      toReturn.push(
        <tr key={i}>
          <td>{transInfo[1]}</td>
          <td>{transInfo[0]}</td>
          <td>{transInfo[3]}</td>
          <td>{transInfo[2]}</td>
        </tr>
      );
    }

    return <tbody>{toReturn}</tbody>;
  };

  const generateAllOrders = () => {
    let toReturn = [];

    for (let i = orders.length - 1; i >= 0; i--) {
      console.log(orders[i]);
      var startTime = new Date(orders[i].date);
      // console.log(startTime);
      startTime = new Date(startTime.getTime()).toLocaleString("sv", {
        timeZone: "America/Denver",
      });
      toReturn.push(
        <tr key={i}>
          <td>{orders[i].fulfilled.toString()}</td>
          <td>
            {orders[i].product.name} x {orders[i].quantity}
          </td>
          <td>-{orders[i].product.pointCost * orders[i].quantity}</td>
          <td>{startTime.split(" ")[0]}</td>
        </tr>
      );
    }

    return <tbody>{toReturn}</tbody>;
  };

  return (
    <div className="user-container">
      <div className="user-main ">
        <div className="user-left-container">
          <div className="user-basic-info user-holder">
            <img
              src={user.picture}
              alt="user profile"
              className="user-basic-info-picture"
            />
            <div className="user-basic-info-text">
              <div className="user-basic-info-top">
                <div className="user-basic-info-name">{user.name}</div>
                {/* <div className="user-basic-info-email">{user.email}</div> */}
              </div>
              <div className="user-basic-info-points">
                Referral: {user.email.split("@")[0]}
              </div>
            </div>
          </div>
          <div className="user-orders-container user-holder">
            <div className="user-header">Orders</div>
            <div className="transaction-info-holder-container">
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Fulfilled</TableCell>
                      <TableCell align="center">Product</TableCell>
                      <TableCell align="center">Point Change</TableCell>
                      <TableCell align="center">Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          {row.fulfilled.toString()}
                        </TableCell>
                        <TableCell align="center">
                          {row.product.name} x {row.quantity}
                        </TableCell>
                        <TableCell align="center">
                          -{row.product.pointCost * row.quantity}
                        </TableCell>
                        <TableCell align="center">
                          {
                            new Date(new Date(row.date).getTime())
                              .toLocaleString("sv", {
                                timeZone: "America/Denver",
                              })
                              .split(" ")[0]
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <table className="transaction-info-holder">
                <thead>
                  <tr>
                    <th>Fulfilled</th>
                    <th>Product</th>
                    <th>Change</th>
                    <th>Date</th>
                  </tr>
                </thead>
                {generateAllOrders()}
              </table> */}
            </div>
          </div>
        </div>
        <div className="user-right-container">
          <div className="user-transaction-container user-holder">
            <div className="user-header">Point Transactions</div>
            <div className="transaction-info-holder-container">
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Reason</TableCell>
                      <TableCell align="center">Points Change</TableCell>
                      <TableCell align="center">Balance</TableCell>
                      <TableCell align="center">Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions.map((row) => (
                      <TableRow
                        key={row[0]}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          {row.split(";")[1]}
                        </TableCell>
                        <TableCell align="center">
                          {row.split(";")[0]}
                        </TableCell>
                        <TableCell align="center">
                          {row.split(";")[3]}
                        </TableCell>
                        <TableCell align="center">
                          {row.split(";")[2]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <table className="transaction-info-holder">
                <thead>
                  <tr>
                    <th>Reason</th>
                    <th>Change</th>
                    <th>Balance</th>
                    <th>Date</th>
                  </tr>
                </thead>
                {generateAllTransactions()}
              </table> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
