import "./FAQBar.css";
import { useEffect, useState } from "react";

const FAQBar = (props) => {
    const [height, setHeight] = useState(59);

    const showBody = (e) => {
        e.target.closest(".faq-bar").classList.toggle("show");
        e.target
            .closest(".faq-bar")
            .querySelector(".faq-expand-img")
            .classList.toggle("show");

        // if (Array e.target.closest(".faq-bar").classList){

        // }
        if (e.target.closest(".faq-bar").classList.contains("show")) {
            setHeight(1000);
        } else {
            e.target.closest(".faq-bar").classList.add("show");

            setTimeout(() => {
                setHeight(document.querySelector(".faq-header").offsetHeight);
                setTimeout(() => {
                    e.target.closest(".faq-bar").classList.remove("show");
                }, 400);
            }, 1);
        }
    };

    useEffect(() => {
        window.onresize = () => {
            if (
                !document.querySelector(".faq-bar").classList.contains("show")
            ) {
                setHeight(document.querySelector(".faq-header").offsetHeight);
            }
        };
        setHeight(document.querySelector(".faq-header").offsetHeight);
    }, []);

    return (
        <div
            className="faq-bar"
            style={{
                maxHeight: height,
            }}
        >
            <div className="faq-header" onClick={showBody}>
                <div className="faq-question">{props.question}</div>
                <button className="faq-expand-btn">
                    <img
                        src={require("../../assets/images/general/arrow-down.png")}
                        alt="drop down"
                        className="faq-expand-img"
                    />
                </button>
            </div>
            <div className="faq-body">{props.body}</div>
        </div>
    );
};

export default FAQBar;
